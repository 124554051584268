<template>
<dir>
<h1>Esta es la pagina de sedes</h1>

</dir>
  
</template>

<script>
export default {};
</script>

<style lang="css" scoped>

.bodycap{
  position:fixed;
  z-index: -100;
  width: 100%;
  height: 100%;
  background-image: url("../../public/fondos/background.jpg");
  background-repeat: no-repeat;
  background-size: 100% 150%;
  left: 0px;
  top: 0px;
}
</style>
